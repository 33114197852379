import { useRouter } from "next/router";
import { ReactElement } from 'react';
import styled from 'styled-components';

import { FeatureCardFragment } from '@/__generated__/graphql';
import {
  BorderRadius,
  breakpoints,
  buttonPrimary,
  buttonPrimaryBlack,
  buttonSecondary,
  buttonSecondaryBlack,
  buttonTemplate,
  colors,
  container,
  eyebrow,
  grid,
  GridWrapper,
  h3,
  h4,
  imageLoaderResponsive,
  mediaQueries
} from '@/theme/lib';
import { useMobile } from '@lib/hooks/useMobile';
import Image from 'next/legacy/image';

import { getLanguage } from "@/lib/config";
import FeatureCardPatterns from '@components/FeatureCardPatterns';

// Styling
type StyledProps = {
  background: string;
};

type StyledAnchorProps = StyledProps & {
  primary?: boolean;
};

const StyledBackground = styled.div<StyledProps>`
  position: relative;
  overflow: hidden;
  background-color: ${(props) => (
    props.background === 'black'
      ? colors.black
      : (props.background === 'White'
        ? colors.white
        : (props.background === 'yellow'
          ? colors.honeyBee
          : props.theme.colors.featureBackground
          )
        )
  )};
  margin-bottom: 3rem;
  ${mediaQueries.lg} {
    margin-bottom: 4rem;
  }
`;

const StyledGridContainer = styled.div`
  ${container}
  ${grid}
  margin-bottom: 0;
  .feature-card-grid-wrapper {
    z-index: 1;
    ${mediaQueries.lg} {
      grid-column: 2 / span 7;
    }
    ${mediaQueries.xl} {
      grid-column: 2 / span 8;
    }
  }
`;

const StyledCard = styled.div<StyledProps>`
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 1.5rem;

  h2 {
    color: ${(props) => ((props.background === 'White' || props.background === 'yellow')
      ? colors.black
      : colors.white
    )};
    margin-bottom: 1.5rem;

    ${mediaQueries.md} {
      max-width: 60%;
    }

    ${mediaQueries.lg} {
      max-width: 75%;
    }
  }

  * {
    background-color: inherit;
  }

  ${mediaQueries.lg} {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
`;

const StyledFlexContainer = styled.div<StyledProps>`
  ${mediaQueries.md} {
    display: grid;
    grid-template-columns: repeat(8, [col-start] 1fr);
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
`;

const StyledPicture = styled.div<StyledProps>`
  width: 100%;
  cursor: pointer;
  aspect-ratio: 16/9;
  position: relative;
  source,
  img {
    object-fit: cover;
    ${(props) => BorderRadius(props.theme.config.componentRadius)}
  }
  > span {
    display: block !important;
  }
`;

const StyledLeftDiv = styled.div`
  ${mediaQueries.md} {
    grid-column: 1 /span 4;
  }
`;

const StyledRightDiv = styled.div<StyledProps>`
  margin-top: 0.6rem;

  span {
    ${eyebrow}
    color: ${(props) => (props.background === 'White'
      ? colors.seagrass
      : (props.background === 'yellow'
        ? colors.black
        : (props.background === 'black' && props.theme.key === 'zsl'
          ? colors.parakeet
          : colors.white)
      )
    )}
  }

  h3 {
    ${h4}
    color: ${(props) => ((props.background === 'White' || props.background === 'yellow')
      ? colors.black
      : colors.white
    )};
    margin-top: 0.25rem;

    ${mediaQueries.md} {
      ${h3}
    }
  }

  p {
    color: ${(props) => ((props.background === 'White' || props.background === 'yellow')
      ? colors.black
      : colors.white
    )};
    margin-top: 0.5rem;

    ${mediaQueries.lg} {
      margin-top: 0.25rem;
    }
  }

  ${mediaQueries.md} {
    grid-column: 5 /span 4;
  }
`;

const StyledAnchorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 0.75rem;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  ${mediaQueries.md} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  ${mediaQueries.lg} {
    margin-top: 0.875rem;
    gap: 1.5rem;
  }
`;

const StyledAnchor = styled.a.withConfig({
  shouldForwardProp: (prop) => prop !== "primary"
})<StyledAnchorProps>`
  ${buttonTemplate}
  ${(props) => props.primary ? buttonPrimary : buttonSecondary}
  transition: all 200ms linear;
  text-align: center;
  color: ${(props) =>
    props.primary
      ? (props.background === 'White' ? colors.white : colors.black)
      : (props.theme.key === 'zsl' ? colors.seagrass : colors.black)
  };
  background: ${(props) =>
    props.primary
      ? props.background === 'White'
        ? colors.seagrass
        : (props.background === 'yellow'
          ? props.theme.colors.button.primary.bg
          : (props.theme.key === 'zsl'
            ? colors.parakeet
            : colors.honeyBee
          )
        )
      : colors.white};
  border-color: ${(props) =>
    props.background === 'White'
      ? (props.primary
          ? 'transparent'
          : colors.seagrass
      )
      : (props.background === 'yellow'
        ? props.theme.colors.button.primary.bg
        : (props.theme.key === 'zsl'
          ? colors.parakeet
          :(props.primary ? colors.honeyBee : props.theme.colors.button.primary.bg)
        )
      )
  };
  border-radius: ${(props) => props.theme.config.buttonRadius};

  &:hover {
    text-decoration: none;
    background: ${(props) =>
      props.theme.key !== 'zsl'
        ? (props.primary ? colors.white : colors.honeyBee)
        : colors.panther
    };
    color: ${(props) =>
      props.theme.key == 'zsl'
        ? colors.parakeet
        : undefined
    };
    border-width: 2px;
    border-color: ${(props) =>
      props.theme.key !== 'zsl'
        ? props.background === 'yellow'
          ? (props.theme.key === 'london'
              ? colors.seagrass
              : colors.redPanda
            )
          : undefined
        : undefined
    };
    &.styled-anchor--White {
      background: ${(props) =>
        props.theme.key == 'zsl'
          ? colors.parakeet
          : undefined
      };
      color: ${(props) =>
        props.theme.key == 'zsl'
          ? colors.panther
          : undefined
      };
    }
  }

  &.styled-anchor--black {
    ${(props) => props.primary ? buttonPrimaryBlack : buttonSecondaryBlack}
  }
`;

// Component
export default function FeatureCard(props: FeatureCardFragment): ReactElement {
  const isMobile = useMobile();
  const router = useRouter();
  const language = getLanguage();
  if (!props?.cards?.length) {
    return <></>;
  }
  const {
    cards: [card],
    secondaryCta,
  } = props;
  const { teaserImage } = card;

  const bgColor = props.backgroundColour ?? 'Seagrass';

  return (
    <StyledBackground background={bgColor} className="feature-card">
      {bgColor !== 'black' && <FeatureCardPatterns backgroundColour={bgColor} />}
      <StyledGridContainer>
        <GridWrapper alignment={'centre-wide'} className="feature-card-grid-wrapper">
          <StyledCard background={bgColor}>
            <h2 lang={language !== 'en' ? language : undefined}>{props.featureCardTitle}</h2>
            <StyledFlexContainer background={bgColor}>
              <StyledLeftDiv>
                {teaserImage && (
                  <StyledPicture background={bgColor}>
                    <Image
                      src={teaserImage.url}
                      alt={teaserImage.altText || ''}
                      layout="intrinsic"
                      width={isMobile ? 345 : 590}
                      height={isMobile ? 191 : 348}
                      objectFit="cover"
                      sizes={`(min-width: ${breakpoints.sm}px) 345px,
                              (min-width: ${breakpoints.md}px) 590px,
                              345px`}
                      loader={imageLoaderResponsive(isMobile ? 345 : 590, isMobile ? 191 : 348)}
                      onClick={() => router && router.push(card.path)}
                    />
                  </StyledPicture>
                )}
              </StyledLeftDiv>
              <StyledRightDiv background={bgColor}>
                {card.teaserEyebrow && <span dangerouslySetInnerHTML={{__html: card.teaserEyebrow}} lang={language !== 'en' ? language : undefined}/>}
                <h3 lang={language !== 'en' ? language : undefined}>{card.title}</h3>
                <p lang={language !== 'en' ? language : undefined}>{card.teaserDescription}</p>
                <StyledAnchorWrapper>
                  {props.primaryCtaLabel && (
                    <StyledAnchor href={card.path} background={bgColor} primary className={`styled-anchor--${ bgColor }`} lang={language !== 'en' ? language : undefined}>
                      {props.primaryCtaLabel}
                    </StyledAnchor>
                  )}
                  {secondaryCta && (
                    <StyledAnchor
                      href={secondaryCta.url}
                      background={bgColor}
                      target={secondaryCta.target ?? undefined}
                      className={`styled-anchor--${ bgColor }`}
                      lang={language !== 'en' ? language : undefined}
                    >
                      {secondaryCta.title}
                    </StyledAnchor>
                  )}
                </StyledAnchorWrapper>
              </StyledRightDiv>
            </StyledFlexContainer>
          </StyledCard>
        </GridWrapper>
      </StyledGridContainer>
    </StyledBackground>
  );
}
